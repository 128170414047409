import React from "react";
import styles from "./netincome.module.scss";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import muiTheme from "../../../utils/muiTheme";
import NumberFormat from "react-number-format";

class NetIncome extends React.Component {
  render() {
    const { answers } = this.props;
    const totalSales = answers[10] ? answers[10].value : null;
    const costOfGoods = answers[11] ? answers[11].value : null;
    const operatingExpenses = answers[12] ? answers[12].value : null;

    if (!totalSales || !costOfGoods || !operatingExpenses) {
      return "";
    }

    const grossProfit = Number((totalSales - costOfGoods).toFixed(2));
    const netIncome = Number((grossProfit - operatingExpenses).toFixed(2));

    return (
      <MuiThemeProvider theme={muiTheme}>
        <div className={styles.container}>
          <span>
            Great! Your Net Income for the valuation comes to
            <strong>
              &nbsp;
              <NumberFormat
                value={netIncome}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
              &nbsp;
            </strong>
            .<br />
            <br />
            If not, you'll need to make changes to the numbers!
          </span>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default NetIncome;
